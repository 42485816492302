import React from "react";
import { Row, Col } from "react-bootstrap";
import styles from "./page-components.module.css";

//Upcoming Events Grid

export const UpcomingEvents = () => {
  const events = [
    {
      id: 1,
      imageUrl: "/img/events/Upcomingevents2024.jpeg",
      // title: "LOYM Spring Event",
      // description:
      //   "Registration to open soon. Attendees will be responsible for meals.",
      // registerUrl:
      //   "https://docs.google.com/forms/u/1/d/e/1FAIpQLSdPrC54tQsrjHG3CB56eSokz3H5rJJT49xSke5uyzSP766znQ/viewform",
    },
    {
      id: 2,
      imageUrl: "/img/events/Upcomingevents2024(1).jpeg",
      // title: "LOYM Spring VBS",
    },
    {
      id: 3,
      imageUrl: "/img/events/modal.jpg",
      title: "LOYM Fall FBS",
      description: `Join us for our next virtual bible study!  It will be a 6 week study (September 16 - October 21, 2024) on the book of Colossians.  
        We will learn how our completeness is found in Jesus, the all-sufficient and supreme ONE for our true joy and happiness  Email 
        loveonyoumommy@gmail.com to request the Zoom link, then get ready to grow with other Moms!  To purchase the book, click on the 
        image below.`,
      registerUrl:
        "https://www.amazon.com/dp/0802416861?ref=cm_sw_r_mwn_dp_H3V06YTMA80G3SNC22Z9&ref_=cm_sw_r_mwn_dp_H3V06YTMA80G3SNC22Z9&social_share=cm_sw_r_mwn_dp_H3V06YTMA80G3SNC22Z9&language=en-US&fbclid=IwY2xjawEx9PxleHRuA2FlbQIxMAABHVEnVSYcJiHYB-R2IYiRllmjT6nuYvDlPmIVcbkv2G3_-Nq7Ov7K7QskBQ_aem_JkwXggp9rV_d4G5xBRAWHQ",
    },
    // Add more events as needed
  ];
  return (
    <Row className="justify-content-md-center">
      {events.map((event) => (
        <Col key={event.id} md={6} sm={12}>
          <div className={styles.eventCard}>
            <div className={styles.imageContainer}>
              <img
                src={event.imageUrl}
                alt={event.title}
                className={styles.eventImage}
                fluid
              />
              <div className={styles.overlay}>
                <h3 className={styles.title}>{event.title}</h3>
                <p className={styles.description}>{event.description}</p>
                {event.registerUrl && (
                  <a
                    href={event.registerUrl}
                    target="_blank"
                    className="button"
                  >
                    Get the book
                  </a>
                )}
              </div>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default UpcomingEvents;
