import React from "react";
import { Helmet } from "react-helmet";
import Header from "./components/page-components/Header";
import Footer from "./components/page-components/Footer";
import PopupFlyer from "./components/page-components/PopupFlyer";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";

// Figure out why Carousel is not showing text
const App = () => {
  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&family=Satisfy&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>
      <Header />
      <br />
      <Container className="my-2">
        <Outlet />
      </Container>
      <Footer />

      {/* <PopupFlyer /> */}
    </>
  );
};

export default App;
