import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IconContext } from "react-icons";
import { FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

const Footer = () => {
  const [isFixed, setIsFixed] = useState(false);

  const handleResize = () => {
    setIsFixed(window.innerHeight - 400 >= document.body.scrollHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    handleResize();

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const footerStyle = {
    position: isFixed ? "fixed" : "relative",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#7e7477ff",
    color: "#ffffff",
    fontFamily: "PT Sans Narrow, sans-serif",
    textAlign: "center",
    padding: "20px",
    margin: "auto",
  };
  const footerText = {
    color: "#ffffff",
  };
  return (
    <>
      <br />
      <br />
      <footer style={footerStyle}>
        <Container>
          <Row>
            {/* Left Column: Social Links */}
            <Col xs={12} md={4} className="mb-3">
              <h2>Connect with us</h2>
              <br />
              <div className="d-flex justify-content-center">
                <IconContext.Provider value={{ size: "2rem", color: "#fff" }}>
                  <a
                    href="https://www.facebook.com/groups/215366642381536/"
                    className="me-2"
                  >
                    <FaFacebook />
                  </a>
                  <a
                    href="https://www.instagram.com/loym.ministry/"
                    className="me-2"
                  >
                    <FaInstagram />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCmMfk0jqTJBFczuph5cOD6g"
                    className="me-2"
                  >
                    <FaYoutube />
                  </a>
                </IconContext.Provider>
              </div>
            </Col>

            {/* Center Column: Client Logo */}
            <Col xs={12} md={4} className="mb-3">
              <div className="d-flex justify-content-center">
                {/* Replace the src attribute with the URL of the client's logo */}
                <img
                  src="/img/Logo-white.png"
                  alt="Client Logo"
                  style={{ maxWidth: "200px", maxHeight: "200px" }}
                />
              </div>
            </Col>

            {/* Right Column: Contact Information */}
            <Col xs={12} md={4} className="mb-3">
              <div className="d-flex justify-content-center">
                {/* Replace the placeholder text with the client's contact information */}
                <div>
                  <p style={footerText}>Email: loveonyoumommy@gmail.com</p>
                  <p style={footerText}>
                    <i>
                      Love on You Mommy Ministry is 501 c3 tax deductible
                      nonprofit
                    </i>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
