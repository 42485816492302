import React from "react";
import ReactDOM from "react-dom/client";

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import store from "./store.js";
import { Provider } from "react-redux";

import App from "./App";

/* SCREENS */
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import WhoScreen from "./screens/WhoScreen/WhoScreen.js";
import ValueScreen from "./screens/ValueScreen/ValueScreen.js";
import HistoryScreen from "./screens/HistoryScreen/HistoryScreen.js";
import FounderScreen from "./screens/FounderScreen/FounderScreen.js";
import BoardScreen from "./screens/BoardScreen/BoardScreen.js";
import EventScreen from "./screens/EventScreen/EventScreen.js";
import CommunityScreen from "./screens/CommunityScreen/CommunityScreen.js";
import DonateScreen from "./screens/DonateScreen/DonateScreen.js";
import TestimonialScreen from "./screens/TestimonialScreen/TestimonialScreen.js";
import StatementScreen from "./screens/StatementScreen/StatementScreen.js";
import ScholarshipScreen from "./screens/ScholarshipScreen/ScholarshipScreen.js";

import "./global.css";
import "bootstrap/dist/css/bootstrap.min.css";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route path="/whoweare" element={<WhoScreen />} />
      <Route path="/values" element={<ValueScreen />} />
      <Route path="/history" element={<HistoryScreen />} />
      <Route path="/founder" element={<FounderScreen />} />
      <Route path="/board" element={<BoardScreen />} />
      <Route path="/events" element={<EventScreen />} />
      <Route path="/outreach" element={<CommunityScreen />} />
      <Route path="/scholarship" element={<ScholarshipScreen />} />
      <Route path="/donate" element={<DonateScreen />} />
      <Route path="/testimonial" element={<TestimonialScreen />} />
      <Route path="/statement" element={<StatementScreen />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);
